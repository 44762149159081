import "./App.css";
import React from "react";

function App() {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <main id="app">
        <nav className="navigation">
          <div className="container-nav">
            <div className="navbar">
              <div className="logo">
                <img src="/images/logo.ico" alt="logo-Onsate"></img>
                <h2>Onsate</h2>
              </div>
              <div className="nav-links">
                <p onClick={() => scrollToSection("onsateInfo")}>Quem somos</p>
                <p onClick={() => scrollToSection("chatbotInfo")}>
                  O que é chat bot
                </p>
                <p onClick={() => scrollToSection("infoContact")}>
                  Fale conosco
                </p>
              </div>
            </div>
          </div>
        </nav>
        <section className="section hero-section">
          <div className="container">
            <div className="flexbox">
              <div className="text-hero">
                <h1>Chatbots transformando empresas</h1>
                <p>
                  Os chatbots automatizam o atendimento ao cliente, melhoram a
                  eficiência e personalizam interações, ajudando empresas a
                  reduzir custos, gerar leads e tomar decisões mais
                  estratégicas.
                </p>
              </div>
              <div className="hero-img">
                <img src="/images/robot.png" alt="hero-image"></img>
              </div>
            </div>
          </div>
        </section>
        <section className="section content-section" id="onsateInfo">
          <div className="container">
            <div className="first-content">
              <div className="first-text">
                <p>
                  Na Onsate, somos especialistas em soluções de chatbot
                  humanizados, projetados para melhorar a comunicação entre
                  empresas e seus clientes de forma natural e eficiente. Nossa
                  equipe desenvolve experiências que simulam conversas reais,
                  evitando interações robóticas e proporcionando um atendimento
                  próximo e ágil. Ajudamos negócios a otimizar o atendimento,
                  reduzir custos e melhorar a conexão com seu público.
                </p>
              </div>
              <div className="first-img">
                <img src="images/img-content.jpg" alt="image-content"></img>
              </div>
            </div>
            <hr className="hr-line"></hr>
            <div className="second-content">
              <div className="second-img">
                <img src="images/img-content2.jpg" alt="image-content"></img>
              </div>
              <div className="second-text">
                <p>
                  Além disso, oferecemos serviços completos de desenvolvimento
                  web. Criamos sites responsivos e funcionais para qualquer
                  plataforma, utilizando tecnologias como HTML, CSS, JavaScript
                  e muito mais. Nossa equipe se dedica a entregar projetos que
                  unem design e performance, garantindo que cada site atenda às
                  necessidades específicas de cada cliente e proporcione uma
                  experiência moderna e intuitiva.
                </p>
              </div>
            </div>
            <hr className="hr-line"></hr>
            <div className="third-content">
              <div className="third-text">
                <p>
                  Além disso, oferecemos serviços completos de desenvolvimento
                  web. Criamos sites responsivos e funcionais para qualquer
                  plataforma, utilizando tecnologias como HTML, CSS, JavaScript
                  e muito mais. Nossa equipe se dedica a entregar projetos que
                  unem design e performance, garantindo que cada site atenda às
                  necessidades específicas de cada cliente e proporcione uma
                  experiência moderna e intuitiva.
                </p>
              </div>
              <div className="third-img">
                <img src="images/img-content3.jpg" alt="image-content"></img>
              </div>
            </div>
          </div>
        </section>
        <section className="section section-explain" id="chatbotInfo">
          <div className="container">
            <div className="Explain-div">
              <div className="card-text">
                <h2>Mas afinal o que é um chatbot?</h2>
                <p>
                  Um chatbot é um software que simula conversas humanas,
                  interagindo por texto ou voz para responder perguntas,
                  realizar tarefas ou fornecer informações. Utilizados em
                  plataformas como sites e apps de mensagens, chatbots
                  automatizam o atendimento ao cliente, economizando tempo e
                  custos para as empresas, operando 24/7 e melhorando a
                  experiência dos usuários ao fornecer respostas rápidas e
                  eficientes.
                </p>
              </div>
              <div className="explain-img">
                <img
                  src="images/img-end-section.jpg"
                  alt="final-img-section"
                ></img>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-end-section" id="infoContact">
          <div className="container">
            <div className="contact">
              <div className="contact-email">
                <h1>Fale Conosco</h1>
                <h3>Contato</h3>
                <p class="email">comercial@onsate.com</p>
              </div>
              <hr className="vertical-line"></hr>
              <div className="enter-contact">
                <p>
                  Entre em contato com a Onsate e transforme a comunicação da
                  sua empresa com nossas soluções personalizadas!
                </p>
                <a
                  href="https://api.whatsapp.com/send?phone=5531975662733&text=Ol%C3%A1%2C%20estou%20interessado%20em%20uma%20conversa%20automatizada%20e%20humanizada%20para%20minha%20empresa."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Entrar em contato
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="footer">
        <p>
          <strong>
            Copyright © 2024 Onsate - Todos os direitos reservados
          </strong>
        </p>
        <div className="wpp-icon">
          <a
            href="https://api.whatsapp.com/send?phone=5531975662733&text=Ol%C3%A1%2C%20estou%20interessado%20em%20uma%20conversa%20automatizada%20e%20humanizada%20para%20minha%20empresa."
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="images/wpp-img.svg" alt="wpp-icon"></img>
          </a>
        </div>
      </footer>
    </>
  );
}

export default App;
